<template>
<Page id="administrator_sms" title="Skicka SMS">
    <Loader v-if="loading" loadingtext="Hämtar användare"></Loader>
    <div v-else class="sms-send">
        <b-select v-model="selected_users" multiple :select-size="20">
            <b-select-option :value="user" v-for="user in users" :key="user.id" :disabled="!can_send(user)">{{user.name + " " + user.lastname}},[{{user.email}}]-[{{ user.phone }}]</b-select-option>
        </b-select>
        <div class="my-4">
            <b-textarea v-model="text">

            </b-textarea>
        </div>
        <div class="text-right">
            <b-button @click="SendSMS()" :disabled="sms_loading" variant="primary">Skicka</b-button>
        </div>        
    </div>
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");

export default {
    name: "Logs",
    components: {
        Page,
        Loader
    },
    data() {
        return {
            selected_users: [],
            text: "",
            sms_loading: false
        };
    },
    metaInfo: {
        title: "Skicka SMS",
        meta: [{
            name: "description",
            content: "Valbara turneringar",
        }, ],
    },
    computed: {
        loading() {
            return this.$store.getters["administratorget/usersloading"];
        },
        users() {
            var items = this.$store.getters["administratorget/users"];
            if (!items)
                return false;
            return items;
        }
    },
    methods: {
        svg(path) {
            return this.$images.svg(path);
        },
        getUser(id) {
            return this.$functions.findById(this.users, "id", id);
        },
        can_send(user) {
            return user.phone
        },
        SendSMS(){
            var sms_data = {
                text: encodeURIComponent(this.text),
                users: this.selected_users
            };
            this.sms_loading = true;
            this.$store.dispatch("administratorpost/SendSMS", sms_data).then(() => {
                this.sms_loading = false;                
                this.text = "";
                this.selected_users= [];
            });
        }
    },
    mounted() {
        this.$store.dispatch("administratorget/getUsers");
    },
};
</script>
